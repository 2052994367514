import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    SimpleForm,
    // NumberInput,
    // required,
    // DateField,
    // ArrayInput,
    // SimpleFormIterator,
    // ReferenceInput,
    // SelectInput,
    ReferenceField,
    ArrayField,
    DateField,
    // BooleanField,
    // ArrayField,
    // BooleanField,
    // BooleanInput,
    // TextInput,
} from "react-admin";

export const PriceModelList = (props) => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <ReferenceField link="show" source="dog" reference="dog" />
            <TextField source="dogLifeStage" />
            <TextField source="priceVersion" />
            <TextField source="breed" />
            <TextField source="calculatedDogDailyPrice" />
        </Datagrid>
    </List>
);

export const PriceModelEdit = (props) => (
    <Edit {...props} redirect="show">
        <SimpleForm>
            {/* <TextInput source="versionNumber" disabled /> */}
            {/* <BooleanInput source="isActive" />
            <ReferenceInput source="updateTo" reference="priceModel" /> */}
        </SimpleForm>
    </Edit>
);

export const PriceModelShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField link="show" source="dog" reference="dog" />
            <TextField source="dogLifeStage" />
            <TextField source="breed" />
            <TextField source="gender" />
            <TextField source="dogWeight" label='Latest Weight Entry' />
            <TextField source="predictedWeight" label='Current Predicted Weight' />
            <TextField source="activityLevel" />
            <TextField source="shape" />
            <ArrayField source="puppyPriceInfo">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="ageInMonth" />
                    <TextField source="predictedWeight" />
                    <TextField source="calculatedCalorie" />
                </Datagrid>
            </ArrayField>
            <TextField source="isNeutered" />
            <DateField source="age" />
            <DateField source="createdAt" />
            <TextField source="calorie" />
            <TextField source="priceVersion" />
            <TextField source="calculatedDogDailyPrice" />
        </SimpleShowLayout>
    </Show>
);

export const PriceModelCreate = (props) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            {/* <BooleanInput source="isActive" />
            <ArrayInput source="prices" validate={required()}>
                <SimpleFormIterator inline>
                    <ReferenceInput source="calorieRange" reference="calorieRange">
                        <SelectInput validate={required()} />
                    </ReferenceInput>
                    <NumberInput source="salmonPrice" helperText={false} validate={required()} />
                    <NumberInput source="chikenPrice" helperText={false} validate={required()} />
                    <NumberInput source="beefPrice" helperText={false} validate={required()} />
                </SimpleFormIterator>
            </ArrayInput> */}
        </SimpleForm>
    </Create>
);