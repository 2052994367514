import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    AutocompleteInput,
    SimpleForm,
    ReferenceInput,
    DateField,
    ReferenceField,
    NumberField,
    WrapperField,
    useRedirect,
    useRecordContext,
    useDelete,
    TopToolbar,
    Confirm,
    EditButton,
    SelectInput,
    SelectArrayInput,
    ReferenceArrayInput,
    ReferenceArrayField,
} from "react-admin";
import Button from '@mui/material/Button'
import { useState } from 'react';
import ReactivationDialog from '../../components/ReactivationDialog'

const filters = [
    <ReferenceInput source="userId" reference="user" alwaysOn >
        <AutocompleteInput label="Filter by User" filterToQuery={searchText => ({ email: searchText })} optionText={"email"} style={{ 'width': '350px' }} />
    </ReferenceInput>,
    <ReferenceInput source="priceVersion" reference="priceVersion" >
        <SelectInput label="Filter by Price Version" optionValue="versionNumber" source="versionNumber" />
    </ReferenceInput>,
    <SelectArrayInput source="status" choices={[
        { id: 'active', name: 'Active' },
        { id: 'paused', name: 'Paused' },
        { id: 'canceled', name: 'Canceled' },
        { id: 'trialing', name: 'Trialing' },
    ]} />
];

const SubscriptionShowActions = ({ setAsideOpen, asideOpen }) => {
    const redirect = useRedirect();
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [method, setMethod] = useState("cancel");

    const [remove, { isLoading, error }] = useDelete(
        'subscription',
        { id: record && record.dog, meta: { method: method } },
        // { onSuccess: () => { handleSuccessfulCancel() }, onError: (error) => { handleErrorCancel(error) } }
    );

    const handleCancelClick = () => {
        setOpen(true);
        setMethod("cancel")
    }
    const handlePauselClick = () => {
        setOpen(true);
        setMethod("pause")
    }
    const handleUpdateClick = () => {
        setAsideOpen(!asideOpen)
    }
    // const handleSuccessfulCancel = () => {
    //     console.log("success")
    //     setTimeout(function () { showSuccess(); }, 500);
    // }
    // const handleErrorCancel = (error) => {
    //     console.log("Error: ", error)
    //     setTimeout(function () { showError() }, 500);
    // }
    const showSuccess = () => {
        setOpen(false)
        redirect('/subscription')
    }
    const showError = () => {
        setOpen(false)
    }
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        // console.log("Cancel Subscription button cliked!")
        remove()
            .then((res) => {
                console.log("res: ", res)
            }).catch((res) => {
                console.log("error: ", res)
            })
        if (error) {
            showError()
        } else {
            showSuccess()
        }

    };
    // const errorAlert = () => (
    //     <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    //         <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    //             This is a success message!
    //         </Alert>
    //     </Snackbar>
    // )
    return (
        <TopToolbar>
            <EditButton style={{ padding: "16px", maxHeight: "16px" }} />
            {/* Add your custom actions */}
            {record && (record.status === 'active' || record.status === 'paused' || record.status === 'trialing') ? <Button color="error" variant="outlined" style={{ padding: "16px", maxHeight: "16px" }} onClick={handleUpdateClick}>{!asideOpen ? "Update Price" : "Close"}</Button> : null}
            {record && (record.status === 'paused') ? <ReactivationDialog meth="resume" id={record.id} /> : null}
            {record && (record.status === 'canceled') ? <ReactivationDialog meth="reactivation" id={record.id} /> : null}
            {record && (record.status === 'active' || record.status === 'trialing') ? <Button color="error" variant="outlined" style={{ padding: "16px", maxHeight: "16px" }} onClick={handlePauselClick}>Pause Subscription</Button> : null}
            {record && (record.status === 'active' || record.status === 'paused' || record.status === 'trialing') ? <Button color="error" variant="outlined" style={{ padding: "16px", maxHeight: "16px" }} onClick={handleCancelClick}>Cancel Subscription</Button> : null}
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`${method === "cancel" ? "Cancel" : "Pause"} Subscription`}
                content={`Are you sure you want to ${method} this subscription?`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                cancel="dismiss"
            />
        </TopToolbar>
    )
}

const Aside = () => {
    const record = useRecordContext();
    return (
        <div style={{ width: 200, margin: '1em' }}>
            <h4 variant="h6">Update Price</h4>
            {record && (
                <Create redirect={false}>
                    <SimpleForm defaultValues={{ subscriptionId: record.id }}>
                        <ReferenceInput link="show" source="priceVersion" reference="priceVersion" />
                    </SimpleForm>
                </Create>
            )}
        </div>
    );
};

export const SubscriptionList = (props) => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={filters} >
        <Datagrid rowClick="show">
            <ReferenceField link="show" source="dog" reference="dog" />
            <ReferenceField link="show" source="userId" reference="user" />
            <NumberField textAlign="left" source="dailyPrice" />
            <TextField source="status" />
            <ReferenceArrayField reference="promoCode" source="discounts"/>
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);

export const SubscriptionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceArrayInput fullWidth source="discounts" reference="promoCode" />
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Edit>
);

export const SubscriptionShow = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <Show {...props} actions={<SubscriptionShowActions setAsideOpen={setOpen} asideOpen={open} />} aside={open ? <Aside /> : null}>
            <SimpleShowLayout>
                <ReferenceField link="show" source="dog" reference="dog" />
                <ReferenceField link="show" source="userId" reference="user" />
                <NumberField textAlign="left" source="dailyPrice" />
                <ReferenceArrayField reference="promoCode" source="discounts"/>
                <TextField source="priceVersion" />
                <TextField source="status" />
                <WrapperField label="Adress: lin1 -> line2 -> city -> state -> postal code" sortBy="shippingAddress.line1">
                    <TextField source="shippingAddress.line1" /> -
                    - <TextField source="shippingAddress.line2" /> -
                    - <TextField source="shippingAddress.city" /> -
                    - <TextField source="shippingAddress.state" /> -
                    - <TextField source="shippingAddress.postalCode" />
                </WrapperField>
                <TextField source="id" />
                <TextField source="__v" label="version" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
            </SimpleShowLayout>
        </Show>
    );
};

export const SubscriptionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Create>
);