import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    SimpleForm,
} from 'react-admin';

export const IngredientList = (props) => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="apiName" />
            <TextField source="id" />
            <TextField source="__v" label="version" />
        </Datagrid>
    </List>
);

export const IngredientEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Edit>
);

export const IngredientShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="apiName" />
            <TextField source="id" />
            <TextField source="__v" label="version" />
        </SimpleShowLayout>
    </Show>
);

export const IngredientCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Create>
);