import { useState } from "react";
import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceInput,
    SimpleForm,
    AutocompleteInput,
    ArrayField,
    ReferenceField,
    DateField,
    NumberField,
    WrapperField,
    SelectArrayInput,
    ImageInput,
    ImageField,
    SelectInput,
    DateInput,
    FormDataConsumer,
    NumberInput,
    useRecordContext,
    useListContext,
    Toolbar,
    SaveButton,
    useUpdateMany,
    TopToolbar,
    // SelectColumnsButton,
    FilterButton,
    CreateButton,
    ExportButton,
    ArrayInput,
    SimpleFormIterator,
    // useRedirect,
} from "react-admin";
import { useFormContext } from 'react-hook-form';
import Button from '@mui/material/Button'

// import { orderStatuses } from "../../config/constants";

const OrderListEditToolbar = (params) => {
    const { getValues } = useFormContext();
    const [updateMany] = useUpdateMany();
    const handleClick = e => {
        e.preventDefault(); // necessary to prevent default SaveButton submit logic
        const data = getValues();
        // console.log("PostCreateActions save button", selectedIds, data)
        updateMany(
            'order',
            { ids: params.selectedIds, data: data },
            // { onSuccess: () => { redirect('list'); } }
        );
    };
    return (
        <Toolbar>
            <SaveButton type="button" color="primary" onClick={handleClick}>Save!</SaveButton>
        </Toolbar>
    );
}

const OrderAside = () => {
    const { data, isPending, selectedIds } = useListContext();
    if (isPending) return null;
    if (!data) return null;
    return (
        <div style={{ width: 200, margin: '1em' }}>
            <h4 variant="h6">Update Orders</h4>
            <Create redirect={false} >
                <SimpleForm toolbar={<OrderListEditToolbar selectedIds={selectedIds} />}>
                    <DateInput source="shipmentDate" label="Shipping Date" />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData.shipmentDate) {
                                return <SelectInput source="carrier" required choices={[
                                    { id: 'fedex', name: 'FedEx' },
                                    { id: 'purolator_ca', name: 'Purolator Canada' },
                                    { id: 'flashbox', name: 'FlashBox' },
                                    { id: 'tyltgo', name: 'Tyltgo' },
                                    { id: 'colaskitchen', name: 'Cola’s Kitchen' },
                                ]} />
                            }
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData.carrier === 'fedex') {
                                return <SelectInput source="service" required choices={[
                                    { id: 'fedex_ground', name: 'FedEx Ground®' },
                                    { id: 'fedex_express_saver', name: 'FedEx Economy' },
                                    { id: 'fedex_standard_overnight', name: 'FedEx Standard Overnight®' },
                                    { id: 'fedex_priority_overnight', name: 'FedEx Priority Overnight®' },
                                    { id: 'fedex_first_overnight', name: 'FedEx First Overnight®' },
                                ]} />
                            } else if (formData.carrier === 'purolator_ca') {
                                return <SelectInput source="service" required choices={[
                                    { id: 'purolator_express', name: 'Purolator Express' },
                                    { id: 'purolator_ground', name: 'Purolator Ground' },
                                ]} />
                            } else if (formData.carrier === 'flashbox') {
                                return <SelectInput source="service" required choices={[
                                    { id: 'flashbox_same_day_delivery', name: 'Same-Day Delivery' },
                                    { id: 'flashbox_next_day_commercial_addresses', name: 'Next-Day Delivery - Commercial Addresses' },
                                ]} />
                            }
                        }}
                    </FormDataConsumer>
                    {/* <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData.carrier === 'fedex') {
                                return <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                                    { id: 'package', name: 'Package' },
                                    // { id: 'fedex_envelope_onerate', name: 'FedEx One Rate® Envelope' },
                                    // { id: 'fedex_extra_large_box_onerate', name: 'FedEx One Rate® Extra Large Box' },
                                    // { id: 'fedex_large_box_onerate', name: 'FedEx One Rate® Large Box' },
                                    // { id: 'fedex_medium_box_onerate', name: 'FedEx One Rate® Medium Box' },
                                    // { id: 'fedex_pak_onerate', name: 'FedEx One Rate® Pak' },
                                    // { id: 'fedex_small_box_onerate', name: 'FedEx One Rate® Small Box' },
                                    // { id: 'fedex_tube_onerate', name: 'FedEx One Rate® Tube' },
                                    // { id: 'fedex_10kg_box', name: 'FedEx® 10kg Box' },
                                    // { id: 'fedex_25kg_box', name: 'FedEx® 25kg Box' },
                                    // { id: 'fedex_box', name: 'FedEx® Box' },
                                    // { id: 'fedex_envelope', name: 'FedEx® Envelope' },
                                    // { id: 'fedex_extra_large_box', name: 'FedEx® Extra Large Box' },
                                    // { id: 'fedex_large_box', name: 'FedEx® Large Box' },
                                    // { id: 'fedex_medium_box', name: 'FedEx® Medium Box' },
                                    // { id: 'fedex_pak', name: 'FedEx® Pak' },
                                    // { id: 'fedex_small_box', name: 'FedEx® Small Box' },
                                    // { id: 'fedex_tube', name: 'FedEx® Tube' },
                                ]} />
                            } else if (formData.carrier === 'purolator_ca') {
                                return <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                                    { id: 'package', name: 'Package' },
                                    // { id: 'express_box', name: 'Express Box' },
                                    // { id: 'express_envelope', name: 'Express Envelope' },
                                    // { id: 'express_pack', name: 'Express Pack' },
                                ]} />
                            } else if (formData.carrier === 'flashbox') {
                                return <SelectInput source="packageCode" defaultValue={'flashbox_parcel'} required choices={[
                                    { id: 'flashbox_parcel', name: 'Parcel' },
                                ]} />
                            }
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData.carrier === 'fedex' || formData.carrier === 'purolator_ca' || formData.carrier === 'flashbox') {
                                return <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                                    { id: "ck_1", name: 'CK10' },
                                    { id: "ck_2", name: 'CK16' },
                                ]} />
                            }
                        }}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData.carrier === 'fedex' || formData.carrier === 'purolator_ca' || formData.carrier === 'flashbox') {
                                return <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (g)" />
                            }
                        }}
                    </FormDataConsumer> */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            if (formData.carrier === 'fedex') {
                                return <ArrayInput source="packages">
                                    <SimpleFormIterator inline>
                                        <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                                            { id: 'package', name: 'Package' },
                                        ]} />
                                        <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (kg)" />
                                        <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                                            { id: "ck_1", name: 'CK10' },
                                            { id: "ck_2", name: 'CK16' },
                                        ]} />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            } else if (formData.carrier === 'purolator_ca') {
                                return <ArrayInput source="items">
                                    <SimpleFormIterator inline>
                                        <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                                            { id: 'package', name: 'Package' },
                                        ]} />
                                        <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (kg)" />
                                        <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                                            { id: "ck_1", name: 'CK10' },
                                            { id: "ck_2", name: 'CK16' },
                                        ]} />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            } else if (formData.carrier === 'flashbox') {
                                return <ArrayInput source="items">
                                    <SimpleFormIterator inline>
                                        <SelectInput source="packageCode" defaultValue={'flashbox_parcel'} required choices={[
                                            { id: 'flashbox_parcel', name: 'Parcel' },
                                        ]} />
                                        <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (kg)" />
                                        <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                                            { id: "ck_1", name: 'CK10' },
                                            { id: "ck_2", name: 'CK16' },
                                        ]} />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            }
                        }}
                    </FormDataConsumer>
                    <SelectInput source="status" choices={[
                        { id: 'shipped', name: 'Shipped' },
                        { id: 'delivered', name: 'Delivered' },
                    ]} />
                </SimpleForm>
            </Create>
        </div>
    );
};

const ListActions = ({ setAsideOpen, asideOpen }) => {
    return (
        <TopToolbar>
            {/* <SelectColumnsButton /> */}
            <FilterButton />
            <CreateButton />
            <ExportButton />
            <Button color="error" variant="outlined" style={{ padding: "16px", maxHeight: "16px" }} onClick={() => setAsideOpen(!asideOpen)}>{!asideOpen ? "Update Bulk Orders" : "Close Bulk Edit"}</Button>
        </TopToolbar>
    );
}

const filters = [
    <ReferenceInput source="userId" reference="user" alwaysOn>
        <AutocompleteInput label="Filter by Email" filterToQuery={searchText => ({ email: searchText })} optionText={"email"} style={{ 'width': '350px' }} />
    </ReferenceInput>,
    <SelectArrayInput
        alwaysOn
        label="Status"
        source="status"
        choices={[
            { id: 'active', name: 'Active' },
            { id: "paused", name: "Paused" },
            { id: 'aggregation', name: 'Aggregation' },
            { id: 'delivered', name: 'Delivered' },
            { id: 'preparation', name: 'Preparation' },
            { id: 'packaging', name: 'Packaging' },
            { id: 'shipped', name: 'Shipped' },
            { id: 'tempPaymentFailed', name: 'TempPaymentFailed' },
            { id: 'trialing', name: 'Trialing' },
            { id: 'skipped', name: 'Skipped' },
            { id: 'canceled', name: 'Canceled' },
            { id: 'uncollectible', name: 'Uncollectible' },
        ]}
    />,
    <SelectArrayInput
        label="Carrier"
        source="carrier"
        choices={[
            { id: 'fedex', name: 'FedEx' },
            { id: 'purolator_ca', name: 'Purolator Canada' },
            { id: 'flashbox', name: 'FlashBox' },
            { id: 'tyltgo', name: 'Tyltgo' },
            { id: 'colaskitchen', name: 'Cola’s Kitchen' },
        ]}
    />,
    <DateInput source="currentPeriodStart_lte" label="Current Period Start After than" defaultValue={new Date()} />,
    <DateInput source="shipmentDate_lte" label="Shipment Date After than" defaultValue={new Date()} />,
];

const OrderNameField = (props) => {
    const record = useRecordContext(props);
    return record ? <span>{record.fullName ? record.fullName : record.name + " " + record.lastName} </span> : null;
}

OrderNameField.defaultProps = { label: 'Name' };


export const OrderList = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} actions={<ListActions setAsideOpen={setOpen} asideOpen={open} />} filters={filters} aside={open ? <OrderAside /> : null} >
            <Datagrid rowClick="show">
                <ReferenceField link="show" label="User" source="userId" reference="user">
                    <OrderNameField />
                </ReferenceField>
                <ReferenceField link="show" label="Dog" source="dog" reference="dog">
                    <TextField textAlign="left" source="name" />
                </ReferenceField>
                <ReferenceField link={false} label="Calorie" source="detail.info[0].recipeId" reference="recipe">
                    <NumberField source="calorie" />
                </ReferenceField>
                {/* <ReferenceField link="show" label="Subscription" source="subscription" reference="subscriptionId">
                <TextField source="id" />
            </ReferenceField> */}
                <DateField source="currentPeriodStart" label="Start Date" />
                <TextField source="detail.recurring" label="recurring" />
                {/* <DateField source="currentPeriodEnd" /> */}
                <TextField source="status" />
                <TextField source="detail.type" label="type" />
                {/* <NumberField textAlign="left" source="detail.dailyPrice" label="dailyPrice" /> */}
                <TextField source="amountPaid" />
                <TextField source="carrier" />
                <WrapperField label="Adress">
                    <TextField source="shippingAddress.line1" />,
                    <TextField source="shippingAddress.line2" />,
                    <TextField source="shippingAddress.city" />,
                    <TextField source="shippingAddress.postalCode" />,
                </WrapperField>
                <TextField source="shippingAddress.state" label="Province" />,
                {/* <TextField source="id" /> */}
                {/* <DateField source="createdAt" /> */}
                <DateField source="updatedAt" />
            </Datagrid>
        </List>
    );
};


export const OrderEdit = (props) => (
    <Edit {...props} redirect="show">
        <SimpleForm>
            <DateInput source="shipmentDate" label="Shipping Date" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.shipmentDate) {
                        return <SelectInput source="carrier" required choices={[
                            { id: 'fedex', name: 'FedEx' },
                            { id: 'purolator_ca', name: 'Purolator Canada' },
                            { id: 'flashbox', name: 'FlashBox' },
                            { id: 'tyltgo', name: 'Tyltgo' },
                            { id: 'colaskitchen', name: 'Cola’s Kitchen' },
                        ]} />
                    }
                }}
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.carrier === 'fedex') {
                        return <SelectInput source="service" required choices={[
                            { id: 'fedex_ground', name: 'FedEx Ground®' },
                            { id: 'fedex_express_saver', name: 'FedEx Economy' },
                            { id: 'fedex_standard_overnight', name: 'FedEx Standard Overnight®' },
                            { id: 'fedex_priority_overnight', name: 'FedEx Priority Overnight®' },
                            { id: 'fedex_first_overnight', name: 'FedEx First Overnight®' },
                        ]} />
                    } else if (formData.carrier === 'purolator_ca') {
                        return <SelectInput source="service" required choices={[
                            { id: 'purolator_express', name: 'Purolator Express' },
                            { id: 'purolator_ground', name: 'Purolator Ground' },
                        ]} />
                    } else if (formData.carrier === 'flashbox') {
                        return <SelectInput source="service" required choices={[
                            { id: 'flashbox_same_day_delivery', name: 'Same-Day Delivery' },
                            { id: 'flashbox_next_day_commercial_addresses', name: 'Next-Day Delivery - Commercial Addresses' },
                        ]} />
                    }
                }}
            </FormDataConsumer>
            {/* <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.carrier === 'fedex') {
                        return <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                            { id: 'package', name: 'Package' },
                            // { id: 'fedex_envelope_onerate', name: 'FedEx One Rate® Envelope' },
                            // { id: 'fedex_extra_large_box_onerate', name: 'FedEx One Rate® Extra Large Box' },
                            // { id: 'fedex_large_box_onerate', name: 'FedEx One Rate® Large Box' },
                            // { id: 'fedex_medium_box_onerate', name: 'FedEx One Rate® Medium Box' },
                            // { id: 'fedex_pak_onerate', name: 'FedEx One Rate® Pak' },
                            // { id: 'fedex_small_box_onerate', name: 'FedEx One Rate® Small Box' },
                            // { id: 'fedex_tube_onerate', name: 'FedEx One Rate® Tube' },
                            // { id: 'fedex_10kg_box', name: 'FedEx® 10kg Box' },
                            // { id: 'fedex_25kg_box', name: 'FedEx® 25kg Box' },
                            // { id: 'fedex_box', name: 'FedEx® Box' },
                            // { id: 'fedex_envelope', name: 'FedEx® Envelope' },
                            // { id: 'fedex_extra_large_box', name: 'FedEx® Extra Large Box' },
                            // { id: 'fedex_large_box', name: 'FedEx® Large Box' },
                            // { id: 'fedex_medium_box', name: 'FedEx® Medium Box' },
                            // { id: 'fedex_pak', name: 'FedEx® Pak' },
                            // { id: 'fedex_small_box', name: 'FedEx® Small Box' },
                            // { id: 'fedex_tube', name: 'FedEx® Tube' },
                        ]} />
                    } else if (formData.carrier === 'purolator_ca') {
                        return <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                            { id: 'package', name: 'Package' },
                            // { id: 'express_box', name: 'Express Box' },
                            // { id: 'express_envelope', name: 'Express Envelope' },
                            // { id: 'express_pack', name: 'Express Pack' },
                        ]} />
                    } else if (formData.carrier === 'flashbox') {
                        return <SelectInput source="packageCode" defaultValue={'flashbox_parcel'} required choices={[
                            { id: 'flashbox_parcel', name: 'Parcel' },
                        ]} />
                    }
                }}
            </FormDataConsumer> */}
            {/* <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.carrier === 'fedex' || formData.carrier === 'purolator_ca' || formData.carrier === 'flashbox') {
                        return <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                            { id: "ck_1", name: 'CK10' },
                            { id: "ck_2", name: 'CK16' },
                        ]} />
                    }
                }}
            </FormDataConsumer> */}
            {/* <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.carrier === 'fedex' || formData.carrier === 'purolator_ca' || formData.carrier === 'flashbox') {
                        return <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (kg)" />
                    }
                }}
            </FormDataConsumer> */}
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.carrier === 'fedex') {
                        return <ArrayInput source="packages">
                            <SimpleFormIterator inline>
                                <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                                    { id: 'package', name: 'Package' },
                                ]} />
                                <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (kg)" />
                                <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                                    { id: "ck_1", name: 'CK10' },
                                    { id: "ck_2", name: 'CK16' },
                                ]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    } else if (formData.carrier === 'purolator_ca') {
                        return <ArrayInput source="items">
                            <SimpleFormIterator inline>
                                <SelectInput source="packageCode" defaultValue={'package'} required choices={[
                                    { id: 'package', name: 'Package' },
                                ]} />
                                <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (kg)" />
                                <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                                    { id: "ck_1", name: 'CK10' },
                                    { id: "ck_2", name: 'CK16' },
                                ]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    } else if (formData.carrier === 'flashbox') {
                        return <ArrayInput source="items">
                            <SimpleFormIterator inline>
                                <SelectInput source="packageCode" defaultValue={'flashbox_parcel'} required choices={[
                                    { id: 'flashbox_parcel', name: 'Parcel' },
                                ]} />
                                <NumberInput source="packageWeight" required defaultValue={2500} label="Package Weight (kg)" />
                                <SelectInput source="dimensionCode" defaultValue={'ck_1'} required choices={[
                                    { id: "ck_1", name: 'CK10' },
                                    { id: "ck_2", name: 'CK16' },
                                ]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                }}
            </FormDataConsumer>
            <SelectInput source="status" choices={[
                { id: 'shipped', name: 'Shipped' },
                { id: 'delivered', name: 'Delivered' },
            ]} />
            <ImageInput source="deliveryProofPicture" label="Delivery Picture" accept={'image/*'} maxSize={7000000}>
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);

const PDField = (props) => {
    const record = useRecordContext(props);
    return record ?
        <>
            {record.labelPdf.map((pdf, i) => {
                return <span style={{ "cursor": "pointer", "color": "#1976d2" }} onClick={() => {
                    const linkSource = `data:application/pdf;base64,${record.labelPdf[i]}`;
                    const downloadLink = document.createElement("a");
                    const fileName = `${record.shippingTrackingNumber[i]}.pdf`;
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                }}>
                    {`${record.shippingTrackingNumber[i]}.pdf`}
                    <br />
                </span>
            })}

        </>
        : null;
}
PDField.defaultProps = { label: 'Created Labels' };

const TrackingNumberURLField = (props) => {
    const record = useRecordContext(props);
    return record ?
        <>
            {record.labelPdf.map((pdf, i) => {
                return <span style={{ "cursor": "pointer", "color": "#1976d2" }} onClick={() => {
                    record.carrier === 'fedex' ?
                        navigator.clipboard.writeText("https://www.fedex.com/wtrk/track/?action=track&trackingnumber=" + record.shippingTrackingNumber[i])
                        :
                        record.carrier === 'purolator_ca' ?
                            navigator.clipboard.writeText("https://www.purolator.com/en/shipping/tracker?pin=" + record.shippingTrackingNumber[i])
                            :
                            navigator.clipboard.writeText("https:/app.colaskitchen.com/sign-in" + record.shippingTrackingNumber[i])

                }}>
                    {record.carrier === 'fedex' ?
                        `https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${record.shippingTrackingNumber[i]}`
                        :
                        record.carrier === 'purolator_ca' ?
                            `https://www.purolator.com/en/shipping/tracker?pin=${record.shippingTrackingNumber[i]}`
                            :
                            `https:/app.colaskitchen.com/sign-in`}

                    <br />
                </span>
            })}

        </>
        : null;
}
TrackingNumberURLField.defaultProps = { label: 'Tracking URLs' };

export const OrderShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField link="show" label="User" source="userId" reference="user">
                <OrderNameField />
            </ReferenceField>
            <ReferenceField link="show" label="Dog" source="dog" reference="dog">
                <TextField source="name" />
            </ReferenceField>
            {/* <ReferenceField link="show" label="Subscription" source="subscription" reference="subscriptionId">
                <TextField source="id" />
            </ReferenceField> */}
            <DateField source="currentPeriodStart" />
            <DateField source="currentPeriodEnd" />
            <NumberField textAlign="left" source="detail.recurring" label="recurring" />
            <NumberField textAlign="left" source="detail.dailyPrice" label="dailyPrice" />
            <NumberField textAlign="left" source="price" label="Total Price" />
            <TextField textAlign="left" source="amountPaid" label="Amount Paid" />
            <TextField source="status" />
            <TextField source="detail.type" label="type" />
            <TextField source="detail.selectedRecipes" label="selectedRecipes" />
            <ArrayField source="detail.info">
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField link="show" source="recipeId" reference="recipe" />
                    <TextField source="amount" />
                </Datagrid>
            </ArrayField>
            <WrapperField label="Adress: lin1 -> line2 -> city -> state -> postal code" sortBy="shippingAddress.line1">
                <TextField source="shippingAddress.line1" /> -
                - <TextField source="shippingAddress.line2" /> -
                - <TextField source="shippingAddress.city" /> -
                - <TextField source="shippingAddress.state" /> -
                - <TextField source="shippingAddress.postalCode" />
            </WrapperField>
            <TextField source="detail.promoCode" />
            <TextField source="id" />
            <TextField source="__v" label="Version" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <DateField source="shipmentDate" label="Shipment Date" />
            <TextField source="carrier" />
            <TextField source="service" />
            <PDField />
            <TrackingNumberURLField />
            <DateField source="deliveryProofPictureDate" showTime />
            <ImageField source="deliveryProofPicture" sx={{ '& img': { minWidth: 400, minHeight: 400, objectFit: 'contain' } }} />
        </SimpleShowLayout>
    </Show>
);

export const OrderCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Create>
);