import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    SimpleForm,
    BooleanField,
    ReferenceField,
    DateField,
    ArrayField,
    NumberField,
    NumberInput,
    BooleanInput,
    SelectArrayInput,
    downloadCSV,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';

const exporter = recipes => {
    const recipesForExport = recipes.map(recipe => {
        const { id, ...recipeForExport } = recipe; // omit backlinks and author
        // postForExport.author_name = post.author.name; // add a field
        return recipeForExport;
    });
    jsonExport(recipesForExport, {
        // headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    }, (err, csv) => {
        var lines = csv.split("\n")
        // console.log("a", lines.length)
        let newCSV = ""
        for (let i = 0; i < lines.length; i++) {
            for (let k = 0; k < lines.length; k++) {
                var cells = lines[k].split(",")
                for (let j = 0; j < cells.length; j++) {
                    if (j === i) {
                        newCSV += csv.split("\n")[k].split(",")[j] + ","
                    }
                }
            }
            if (i === 8) {
                newCSV += "\nIngredients\n"
            } else if (i === 23) {
                newCSV += "\nAdditional Info\n"
            } else {
                newCSV += "\n"
            }
        }
        // console.log("CVS")
        // console.log(recipesForExport)
        // console.log(csv)
        // console.log(newCSV)
        downloadCSV(newCSV, 'RecipesSplited'); // download as 'posts.csv` file
    });
};

const filters = [
    <BooleanInput source="isPregnant" label="Is Pregnant" defaultValue={false} />,
    <BooleanInput source="isNursing" label="Is Nursing" defaultValue={false} />,
    <NumberInput source="age_gte" label="Younger than" defaultValue={108} />,
    <NumberInput source="age_lte" label="Older than" defaultValue={12} />,
    <NumberInput source="weight_lte" label="Max Weight" defaultValue={25} />,
    <NumberInput source="weight_gte" label="Min Weight" defaultValue={15} />,
    <SelectArrayInput source="recipeId" label="Type" choices={[
        { id: '1', name: 'Beef' },
        { id: '2', name: 'Chiken' },
        { id: '3', name: 'Salmon' },
    ]} />
];

export const RecipeList = (props) => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={filters} exporter={exporter} >
        <Datagrid rowClick="show">
            <TextField source="recipeId" />
            <ReferenceField link="show" label="Dog" source="dog" reference="dog">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField link="show" label="Owner" source="owner" reference="user">
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isActive" />
            <TextField source="id" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);

export const RecipeEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Edit>
);

export const RecipeShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="recipeId" />
            <ReferenceField link="show" label="Dog" source="dog" reference="dog">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField link="show" label="Owner" source="owner" reference="user">
                <TextField source="name" />
            </ReferenceField>
            <ArrayField source="ingredients">
                <Datagrid bulkActionButtons={false}>
                    <ReferenceField link="show" source="ingredient" reference="ingredient" />
                    <NumberField textAlign="left" source="amount" />
                </Datagrid>
            </ArrayField>
            <NumberField textAlign="left" source="digestibleProtein" />
            <NumberField textAlign="left" source="crudeProtein" />
            <NumberField textAlign="left" source="digestibleProtein" />
            <NumberField textAlign="left" source="metabolizableEnergy" />
            <NumberField textAlign="left" source="ME" />
            <NumberField textAlign="left" source="fiber" />
            <NumberField textAlign="left" source="weight" />
            <NumberField textAlign="left" source="calorie" />
            <NumberField textAlign="left" source="price" />
            <NumberField textAlign="left" source="dogPrice" />
            <BooleanField source="isActive" />
            <TextField source="id" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

export const RecipeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Create>
);