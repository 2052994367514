import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    SimpleForm,
    NumberInput,
    required,
    DateField,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    ArrayField,
    BooleanField,
    BooleanInput,
    // TextInput,
} from "react-admin";

export const PriceVersionList = (props) => (
    <List {...props} sort={{ field: 'createdAt', order: 'ASC' }} >
        <Datagrid rowClick="show">
            <TextField source="versionNumber" />
            <BooleanField source="isActive" />
            <ReferenceField source="updateTo" reference="priceVersion" />
        </Datagrid>
    </List>
);

export const PriceVersionEdit = (props) => (
    <Edit {...props} redirect="show">
        <SimpleForm>
            {/* <TextInput source="versionNumber" disabled /> */}
            <BooleanInput source="isActive" />
            <ReferenceInput source="updateTo" reference="priceVersion" />
        </SimpleForm>
    </Edit>
);

export const PriceVersionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="versionNumber" />
            <BooleanField source="isActive" />
            <ReferenceField source="updateTo" reference="priceVersion" />
            <ArrayField source="prices">
                <Datagrid>
                    <ReferenceField source="calorieRange" reference="calorieRange" />
                    <TextField source="salmonPrice" />
                    <TextField source="chikenPrice" />
                    <TextField source="beefPrice" />
                </Datagrid>
            </ArrayField>
            <TextField source="id" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

export const PriceVersionCreate = (props) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <BooleanInput source="isActive" />
            <ArrayInput source="prices" validate={required()}>
                <SimpleFormIterator inline>
                    <ReferenceInput source="calorieRange" reference="calorieRange">
                        <SelectInput validate={required()} />
                    </ReferenceInput>
                    <NumberInput source="salmonPrice" helperText={false} validate={required()} />
                    <NumberInput source="chikenPrice" helperText={false} validate={required()} />
                    <NumberInput source="beefPrice" helperText={false} validate={required()} />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);