import {
    Datagrid,
    List,
    Show,
    Edit,
    Create,
    // required,
    TextField,
    SimpleShowLayout,
    TextInput,
    SimpleForm,
    DateField,
    NumberInput,
    SelectInput,
    required,
    BooleanInput,
    BooleanField,
    AutocompleteInput,
    useRecordContext,
} from "react-admin";
import { activityLevel, dogBreeds, shape } from "../../config/constants";

// const filters = [
//     <TextInput label="Filter by Email" source="email" alwaysOn style={{ 'width': '350px' }} />,
// ];

const URLField = (props) => {
    const record = useRecordContext(props);
    return record ?
        <span style={{ "cursor": "pointer", "color":"#1976d2" }} onClick={() => { navigator.clipboard.writeText("https://app.colaskitchen.com/breeder/" + record.name) }}>
            {"https://app.colaskitchen.com/breeder/"}{record.name}
        </span> : null;
}
URLField.defaultProps = { label: 'URL' };

export const BreederList = (props) => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="breed" />
            <DateField source="createdAt" />
            <URLField />
        </Datagrid>
    </List>
);

export const BreederEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <AutocompleteInput source="breed" choices={dogBreeds.map(item => ({ id: item, name: item }))} fullWidth />
            <NumberInput source="weight" />
            <SelectInput source="activityLevel" choices={activityLevel.map(item => ({ id: item, name: item }))} />
            <BooleanInput source="isNeutered" />
            <SelectInput source="shape" choices={shape.map(item => ({ id: item, name: item }))} />
            <NumberInput source="age" label="Age (weeks)" />
        </SimpleForm>
    </Edit>
);

export const BreederCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <AutocompleteInput source="breed" choices={dogBreeds.map(item => ({ id: item, name: item }))} fullWidth />
            <NumberInput source="weight" />
            <SelectInput source="activityLevel" choices={activityLevel.map(item => ({ id: item, name: item }))} />
            <BooleanInput source="isNeutered" />
            <SelectInput source="shape" choices={shape.map(item => ({ id: item, name: item }))} />
            <NumberInput source="age" label="Age (weeks)" />
        </SimpleForm>
    </Create>
);

export const BreederShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="breed" />
            <TextField source="weight" />
            <TextField source="activityLevel" />
            <BooleanField source="isNeutered" />
            <TextField source="shape" />
            <TextField source="age" />
            <TextField source="id" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <URLField />
        </SimpleShowLayout>
    </Show>
);
