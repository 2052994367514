import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
    SimpleForm,
    ArrayField,
    DateField,
    ReferenceField,
} from "react-admin";

const filters = [
    <TextInput label="Filter by Email" source="email" alwaysOn style={{'width': '350px'}}/>,
];

export const StripeCustomerList = (props) => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={filters} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="email" />
            <TextField source="stripeCustomerId" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);

export const StripeCustomerEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Edit>
);

export const StripeCustomerShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="email" />
            <TextField source="stripeCustomerId" />
            <ReferenceField link="show" source="userId" reference="user" />
            <ArrayField source="subscriptions">
                <Datagrid bulkActionButtons={false}>
                    {/* <ReferenceField link="show" source="subscriptionId" reference="subscription" /> */}
                    <TextField source="subscriptionId" />
                </Datagrid>
            </ArrayField>
            <TextField source="id" />
            <TextField source="__v" label="version" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

export const StripeCustomerCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Create>
);