import {
  Datagrid,
  List,
  Show,
  Edit,
  Create,
  // required,
  TextField,
  SimpleShowLayout,
  TextInput,
  SimpleForm,
  DateField,
  ReferenceManyField,
} from "react-admin";

const filters = [
  <TextInput label="Filter by Email" source="email" alwaysOn  style={{'width': '350px'}}/>,
];

export const UserList = (props) => (
  <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={filters} >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      {/* <TextInput source="name" validate={required()} />
      <TextInput source="email" validate={required()} /> */}
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      {/* <TextInput source="key" validate={required()} fullWidth multiline />
      <TextInput source="value" validate={required()} /> */}
    </SimpleForm>
  </Create>
);

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="phoneNumber" />
      <ReferenceManyField label="Dogs" reference="dog" target="owner">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
        </Datagrid>
      </ReferenceManyField>
      <TextField source="referral" />
      <TextField source="id" />
      <TextField source="__v" label="version" />
      <DateField source="lastLoginDate" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
