import {
    Datagrid,
    List,
    Show,
    Edit,
    Create,
    // required,
    TextField,
    SimpleShowLayout,
    SimpleForm,
    ArrayField,
} from "react-admin";

// const filters = [
//     <TextInput label="Filter by Email" source="email" alwaysOn style={{ 'width': '350px' }} />,
// ];


export const GrowthPatternList = (props) => (
    <List {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="minWeight" />
            <TextField source="maxWeight" />
        </Datagrid>
    </List>
);

export const GrowthPatternEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
        </SimpleForm>
    </Edit>
);

export const GrowthPatternCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <AutocompleteInput source="name" choices={dogBreeds.map(item => ({ id: item, name: item }))} fullWidth /> */}
        </SimpleForm>
    </Create>
);

export const GrowthPatternShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="minWeight" />
            <TextField source="maxWeight" />
            <ArrayField source="patternInfo">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="month" />
                    <TextField source="coef" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
